.image-drag-inactive-editable {
    button.edit-button {
        margin-top: 4px;
        padding: 0 5px 0 5px;
        background-color: transparent !important;   /* !important needed unless you want to figure out how to override all the various Bootstrap states.*/
        border-color: #dddddd !important;
        cursor: pointer;
        float: right;
        z-index: 1000;
        margin-right: 5px;
    }
}

.pen-icon {
    color: white;
    left: 300px;
}