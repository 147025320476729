.document-item {
    
    margin: 10px;
    border: 1px solid lightgrey;
    padding: 10px 15px 10px 0;

    .document-label::-webkit-input-placeholder
    {
        color: rgba(0,0,0,0.3);
    }

    .document-label::-moz-placeholder
    {
        color: rgba(0,0,0,0.3);
    }

    .document-label-ms-input-placeholder
    {
        color: rgba(0,0,0,0.3);
    }

    .icon-container {
        border: 1px solid lightgrey;
        vertical-align: middle;
        height: 30px;
        width: 30px;

        svg {
            top: -2px;
            position: relative;
        }
    }

    .dropdown {

        button {
            width: 170px;
        }
    }
}