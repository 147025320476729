.images-selected {
    display: flex;
    justify-content: space-between;
}

.display-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.margin-top-10 {
    margin-top: 10px;
}

.invalid-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
}