.placeholder {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height:10vw;
    width: 10vw;    
    margin-left: 40%;
    position: relative;    
    margin-bottom: 40px;
    &.selected {
        border: 1px solid rgba(0, 0, 0, 0.125);
        background-color: rgba(151, 205, 39, 0.4);
    }
}

.card-page13-container {
    text-align: center;
    height: 600px;
    padding-top: 10%;
}

.padding-top-20{
    padding-top: 20px;
}