.profile-status-toggle-button {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
}

.dropdown-menu {
    z-index: 1030;
}