.profile-container {
    .sidebar-col {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .profile-form-display {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-body {
        padding-bottom: 0px;
    } 
    .page-container {
        margin-left: 15%;
        margin-right: 5%;
    }
    
    .profile-navigation-buttons-container {
        display: flex;
        justify-content: flex-end;
        button {
            height: 38px;
        }
        margin-top: 40px;
    }
    
    .view-ibrochure-container {
        margin-top: 40px;
    }
    
    .save-container {
        margin-top: 40px;
    }
    
    .help-button {
        float: right;
        margin-top: 130px;
    }
    
    .container-title {
        display: flex;
        align-items: flex-end;
    
        h2 {
            margin-bottom: 0px;
        }
    }
    
    .sticky-actions {
        position: -webkit-sticky;
        position: sticky;
        top: 200px;
    }
    
}
.margin-top-50 {
    margin-top: 50px;
}

.height-100 {
    height: 100%;
}

.border-radius-none {
    border-radius: 0px;
}

.incomplete-dot {
    color: lightgrey;
}

.incomplete-dot-media {
    color: red;
}

.complete-dot {
    color: #2596BE;
}

.section-dot {
    position: relative;
    left: -10px;
}

.profile-form-display {
    .central-content-section {
        .section-dot {
            position: absolute;
            left: -30px;
        }
        .card-subtitle {
            padding-left: 15px;
        }
        .card-body {
           
            .form-check {
                input {
                    position: static;
                    margin-left: 0;
                }
                label {
                    padding-left: 10px;
                }
    
            }
            .card-title {
                position: relative;
                //padding-left: 15px;
            }
            .card-title-section {
                justify-content: flex-start;
                padding-left: 15px;
                position: relative;
            }
            .btn-link {
                padding-top: 0;
            }
        }
        .videos-card, .virtual-tours-card {
            .card-title-section {
                justify-content: space-between;
            }
        }
    }
}
