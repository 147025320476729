.gm-ui-hover-effect {
    display: none !important;
}
.gm-style-iw, .gm-style-iw-d {
    background-color: rgb(70, 70, 70) !important;
    color: white;
}

.gm-style .gm-style-iw-t::after {
    background: linear-gradient(45deg,rgba(70,70,70,1) 50%,rgba(70,70,70,0) 51%,rgba(70,70,70,0) 100%);
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
	background-color: rgb(70, 70, 70)
}