
.profile-row{
    height: 80px;

    .profile-cell{
        padding: 0;
    }

    .cell-wrapper{
        align-content: center;
        display: flex;
        height: 80px;
        align-items: center;
        padding-left: 10px;

        .progress{
            width: 100%;
        }
        
        .room-image{
            max-height: 80px;
            max-width: 80px;
            width: auto;
            height: auto;
        }
        .name-text{
            padding-left: 10px;
            display:flex;
            flex-direction: column;
            justify-content: center;
            
            .eye-icon{
                cursor: pointer;
                margin-left: 10px;
            }

            .category-name{
                font-size: 12px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}


