.card-title-section {
    display: flex;
    justify-content: space-between;
}

.videos-card {
    margin-bottom: 1.5em;

     .form-text, .card-text {
        padding-left: 15px;
    }
}