.text-input-group{
    padding-left: 10px;
    margin-bottom: 0;
    width: 325px;
}

.disabled{
    pointer-events: none;
    opacity: 0.7;
    background-color: #e9ecef;
    border-radius: 5px;
}

.card-container{
    padding-left: 10px;
    display: flex;
    padding-top: 10px;
}

.add-text{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    
    .add-text-div{
        display: flex;
        border-style: solid;
        border-width: 1px; 
        height: 38px;
        border-left: none;
        cursor: pointer;
        border-color: #ced4da;
        border-radius: 5px;
        align-items: center;
        width: 100%;
        padding-left: 5px;
        border-left-style: none;
        
        .bold{
            font-weight: bold;
            padding-right: 5px;
        }
    }

    .add-button{
        display: flex;
        width: 40px;
        border: solid;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-width: 1px;
        align-items: center;
        justify-content: center;
        border-color: #ced4da;
        cursor: pointer;
        border-right-style: none;
    
        .add-cross{
            cursor: pointer;
        }
    }
}

.label-pill{
    cursor: default;
    border-style: solid;
    border-width: 1px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left:5px;
    
    .cross{
        cursor: pointer;
    }

}

.additional-row{
    padding-left: 10px;
}