.profile-empty-page {
    flex: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 54px);

    .profile-empty-page-introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.display-flex {
    display: flex;
}

.button-margin {
    margin: 5px;
}
