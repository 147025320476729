#header {
    li {
        list-style: none;
        color: #212529;
    }

    .col-md-1 {
        height: 100%;
        top: 25%;
        width: 100px;
    }

    .current {
        li {
            span {
                padding-bottom: 13px;
                border-bottom: 4px solid #555555;
            }
        }
    }

    .placeholder-user-profile {
        border: 1px solid #777777;
        width: 150px;
        padding: 3px;
        text-align: center;
    }
    
    .logo{
        height: 40px;
        width: 100px;
    }
    
    a:hover {
        text-decoration: none;
    }
}

.bg-light-grey {
    background-color: lightgray;
}