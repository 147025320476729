.sort-label{
    display: flex;
    cursor: pointer;

    .arrows{
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5px;
    }
}