.profile-item-modal {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .sticky-modal-header {
        position: fixed;
        z-index: 99999;
        top: 0;
        width: 100%;
        background-color: white;
    }

    .delete-arrangement-button {
        margin-top: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }

    .radio-col {
        display: flex;
        justify-content: center;
        align-items: center;

        .radio-label {
            margin-top: 20px;

            .radio-label-span {
                margin-left: 5px;
            }
        }
    }

    .overlay-body-container {
        padding: 1.25rem;
    }

    .sleeping-arrangements-images-selected {
        padding-left: 15px;
    }

    .overlay-card {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .container-fluid-height{
        height:100%
    }

    .container-background {        
        padding: 0;
        margin: 0;
    }

    .spinner-border {
        position: absolute;
        left: 50%;
    }

    .header-container {
        padding-right: 0;        
        max-width: 100%;
        margin: 0;
        justify-content: space-between;
        display: flex;
        .row {
            width: 100%;
        }
    }

    .header-inner-container {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }
    
    .close {
        margin-left: 0;
    }

    .save {
        margin-left: 15px;
    }

    .back-next-button {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 36px;
        width: 36px;
    }
    
    .modal-dialog {
        width: 100% ;
        height: 100% ;
        margin: 0;
        padding: 0;
    }

    .modal-body {
        flex-wrap: nowrap;
        padding:  5px 10px 0px 10px;
        margin-top: 60px;
    }

    .modal-content {
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
        height: 100%;
        min-height: 100%;
        border-radius: 0;
        width: 100%;
    }

    .room-size-container {
        display: flex;
        align-content: center;

        .room-size-input {
            width: 33%;
            margin-right: 8px;
        }

        .radio-label {
            display: flex;
            margin-bottom: 0px;
            align-items: center;
            margin-right: 4px;

            input[type="radio"] {
                margin: 4px;
            }
        }
    }

    .alert {
        .alert-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .alert-header-text {
                display: flex;
                font-size: 18px;
            }

            .close-button {
                cursor: pointer;
            }
        }
        

        button {
            padding-left: 0px;
        }
    }

    

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 100%;
            margin: 0;
        }
    }

    .modal-content {
        overflow: auto;
    }
}