.number-stepper {
  .btn-group {
    width: 180px;
    border-radius: 0 !important;
    input {
        border-radius: 0;
        text-align: center;
    }
    button {
        border-radius: 0;
    }
}

  /* Remove arrows/spinners (Chrome, Safari, Edge, Opera) */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove arrows/spinners (Firefox) */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .number-stepper-col {
    padding: 0px;
  }
}
