.insights-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin: 20px 0 10px 0;
    color: grey;
    max-height: 400px;
    padding-bottom: 1.5em;

    .card-body{
        display: flex;
        flex-direction: column;

        .card-subtitle {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .card-text {
            display: flex;
            font-size: 12px;
            word-break: break-word;
        }
    }

    p {
        margin-bottom: 0px;
    }
}

.sticky-insights-card {
    position: -webkit-sticky;
    position: sticky;
    top: 540px;
}






