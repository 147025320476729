.time-picker-dropdown > .dropdown {
    max-height: 50px !important;
    button {
        width: 160px !important;
    }
    background-color: white !important;
}

.time-picker-dropdown > .dropdown > .dropdown-menu{
    max-height: 200px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    width: 160px !important;
    padding: 0;
    border-radius: 0;
    margin: 0;
    a {
        text-align: center;
    }
}

.dropdown-item-container {
    text-align: center;
    width: 160px !important;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.dropdown-item-container:last-child {
    border-bottom: 0;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: rgb(131, 131, 131);
}