$greenDefault: #97CD27;
$greenSpinnerBg: rgba(151, 205, 39, 0.4);
$greyInputBorder: rgba(8, 21, 77, 0.1);
$greyInputBg: #F9FAFE;
$greyText: rgba(66, 70, 82, 0.8);
$redError: #D0081B;


.email-input-wrap {
    width: 100%;

    .email-input {
        position: relative;
    }

    .invalid-feedback {
        color: $redError;
        font-size: 10px;
        line-height: 14px;

        > div {
            display: flex;
        }

        a {
            margin-left: 3px;
        }

        img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }

    input.form-control {
        border-color: $greyInputBorder;
        background-color: $greyInputBg;
        color: $greyText;

        &.is-invalid {
            border-color: $redError;
            background-image: none;
            background-color: #FAE5E7;

            &:focus {
                border-color: $redError;
                box-shadow: 0px 0px 3px $redError;
            }
        }

        &.is-valid {
            border-color: $greyInputBorder;
            background-image: url(images/icon_success.svg);
            background-size: 14px 14px;

            &:focus {
                border-color: $greenDefault;
                box-shadow: 0px 0px 3px $greenDefault;
            }
        }
    }

    .spinner {
        position: absolute;
        top: 10px;
        right: 11px;

        &.spinner-border-sm {
            border-width: 3px;
            width: 16px;
            height: 16px;
        }

        &.text-primary {
            color: #97CD27 !important;
        }

        &:before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 3px solid $greenSpinnerBg;
        }
    }
}
