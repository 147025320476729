.draggable-container {
    background-color: #eee;
    padding: 12px 10px 1px 20px;
    cursor: move;
    margin-top: 2px;
    position: relative;
    
    .drag-icon {
        position: absolute;
        left: 10px;
        top: 60px;
        path {
            stroke: grey;
        }
    }
}
