.wizard-card-wrap{
    padding: 0 !important;  /*!important needed to override Bootstrap col classes*/
}

.wizard-card {
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 9vw;
    margin-bottom: 1vw;
    margin-right: 1vw;
    color: grey;

    &.hovered {
        border-color: #ACD752;
    }

    &.selected {
        border-color: #97CD27;
    }

    .card-body{
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-subtitle {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .card-text {
            font-size: 0.8vw;        
        }

        .circle {
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.125);
            height:3vw;
            width: 3vw;
            text-align: center;
            padding-top: 14px;
                    
            &.selected {
                border: 1px solid rgba(0, 0, 0, 0.125);
                background-color: rgba(151, 205, 39, 0.4);
            }
        }
    }
}
