.expanded-sidebar {
    height: 100%;
    border-right: 1px solid lightgray;
    transition: 1s;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.collapsed-sidebar {
    height: 100%;
    border-right: 1px solid lightgray;
    transition: 1s;
    width: 25%;
    justify-content: flex-start;
    align-items: flex-start;

    div {
        display: flex;
        justify-content: center;
    }
}
.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.width-100 {
    width: 100%;
}

.nav-link-border-left {
    border-left: 2px solid lightgrey;
}

.sticky-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
}