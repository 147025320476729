.map-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 10px;
    height: 450px;
    width: 100%;
    align-content: flex-start;
}

.enquire-link {
    display: flex;
    align-items: flex-start;
    width: 50%;
    margin: 10px 0px 20px;
}

.margin-top-20 {
    padding-top: 20px;
}

.padding-top-10 {
    padding-top: 10px;
}