.contact-details {
    .card-body {
        .card-title {
            padding-bottom: 20px;
        }

        .card-subtitle {
            padding: 0 0 7px 0;
        }

        .container-fluid {
            //We need to override all the added margin/padding from Bootstrap...
            padding: 0;
            margin: 0;
        }
        
        .two-col-content {
            padding-right: 15px;
        }

        .completed-dot {
            margin-top: 0;
            position: absolute;
            top: -10px;
        }

        .row {
            margin-left: 0;
        }

        .left-col {
            padding: 0 7px 0 0;
        }

        .tooltip-label {
            position: relative;
            svg {
                top: 4px;
                margin-left: 7px;
            }

        }
    
        .invalid-url {
            background: #FAE5E7;
            border-color: #CE1024;
        }

        .red-icon {
            color:#CE1024;
        }

        .multi-section-card-title {
            .multiselect {
                width: 270px;
                .dropdown-menu {
                    width: 270px;
                }
            }
        }

        .title-wrapper-div {
            display: flex;
            justify-content: space-between;
            padding-bottom: 7px;
        }

        .input-wrapper {
            display: flex;
            justify-content: space-between;
            
            .gds-trash {
                position: relative;
                
                svg {
                    position: absolute;
                    margin: 9px 0 0 15px;
                }
            }
        }

        .gds-trash {
            margin-left: 4px;
            cursor: pointer;

            .trash-icon {
                color: darkgrey;
            }
        }

        .is-required {
            .react-tel-input {
                input {
                    background: #FAE5E7;
                    border-color: #CE1024;
                }
            }
        }

        .reservation-section {
            .show-more {
                .col {
                    padding: 0;
                    button {
                        padding: 0;
                    }
                }

                .react-tel-input {
                    padding-right: 15px;
                }
            }
        }

        .contact-section {
            padding-top: 25px;
        }
    }
}

/* react-bootstrap OverlayTrigger and Tooltip are added as direct children of <body>.
So you have to access them by ID, and outside of the page structure */
#tooltip-url-to-res-system  {
    width: 295px;
    max-width: 295px;
    .tooltip-inner {
        width: 295px;
        max-width: 295px;
    }
}