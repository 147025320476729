.filter-bar-border {
    border: 1px solid #dee2e6;
    height: 65px;
    display: flex;
    justify-content: space-between;

    .navbar-nav .nav-link {
        color: black;
    }

    .nav-link-border-bottom {
        border-bottom: 5px solid #dee2e6;
    }

    .dropdown {
        border: 1px solid #343a40;
        border-radius: .25rem;
        margin-left: 5px;
        margin-right: 10px;
    }

    .flex-center {
        display: flex;
        justify-content: center;
    }

    #filter-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #filter-tier {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #search-filter-icon {
        border-top-left-radius: .25rem;
        border-top-right-radius: 0;
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: 0;
        border: 1px solid #343a40;
        border-right: 0px;
        background-color: white;
    }

    #search-filter input {
        height: 100%;
        border: 1px solid #343a40;
        border-left: 0px;
        padding-left: 0px;
        margin-right: 10px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }
}