.language-nav {
    a {
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    a:hover {
        color: black;
    }

    .active {
        background-color: white;   
    }
    
    .nav-item {
        padding: 5px;
    }

    .language-icon-margin-right {
        margin-right: 0.25rem;
    }
    
    .carousel-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
    }
    
    .nav-link-inactive {
        color: #686B75;
    }
    
    .nav-link-active {
        border-bottom: 2px solid #6cb741;
        color: black;
    }

    .nav-link-inactive:hover {
        border-bottom: 2px solid lightgrey;
    }
}

.language-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        scroll-behavior: smooth;

        .nav-item {
            white-space: nowrap;
            
            .nav-link {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

    ::-webkit-scrollbar {
        display: none;
    }
}