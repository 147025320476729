.image-drag-inactive-editable {
    button.edit-button {
        margin-top: 4px;
        padding: 0 5px 0 5px;
        background-color: transparent !important;   /* !important needed unless you want to figure out how to override all the various Bootstrap states.*/
        border-color: #dddddd !important;
        cursor: pointer;
        float: right;
        z-index: 1000;
    }
}

.pen-icon {
    color: white;
    left: 300px;
}

.iframe-container { 
    width: 100%;
    height: 100%;
    
    height: calc(100% - 36px);
    display: flex;
    justify-content: center;
    align-items: center;
}


.overlay-title {
    position: absolute;
    background: #000431;
    opacity: 0.6;
    color: white;
    padding: 6px 10px;
    bottom:0px;
    width: 100%;
  
    right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }