.gds-card {
    padding-bottom: 1.25rem;

    .gds-card-title-section {
        display: flex;
        justify-content: space-between;
    }

    .gds-input {
        margin: 4px;
        width: 50%;

        .gds-input-display {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .gds-trash {
                margin-left: 4px;
                cursor: pointer;

                .trash-icon {
                    color: darkgrey;
                }
            }
        }
    }
}