$primary: #6cb741;

.profiles {
    flex: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 54px);

    .profiles-introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.loading-msg {
    margin-top: 50px;
    text-align: center;
}
.spinner {
    color: $primary;
    margin-top: 300px;
}
