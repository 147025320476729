.form-label {
    font-weight: 500;
}
.radio-container {
    vertical-align: text-top;
    input {
        margin-top: 5px;
        vertical-align: top;
    }
    .time-format-label2 {
        margin-left: 3px;
    }
}
.text-label {
    font-size: 1em;
}
.padding-0 {
    padding: 0;
}
.label-small {
    font-size: 0.8em;
}
.facilities-container {
    .row {
        margin-bottom: 0;
    }
    .container-fluid {
        margin: 15px 0 0 0;
        padding-left: 0;
        .title-container {
            font-weight: 500;
        }
    }
}
.accordion-container {
    margin-bottom: 10px;
}
#min-age-input {
    width: 100px;
}
.trash-button {
    float: right;
    margin: 13px 0 0 25px;
    cursor: pointer;
}
.alt-image-rating {
    width: 50%;
}
.number-input-container {
    display: flex;
}
.col-5 {
    padding-left: 0;
}
.btn-group {
    padding-left: 0;
}
.text-input-group {
    padding-left: 0;
}