.field-descriptor {
    font-size: 0.9rem;
    align-items: left !important;
    text-align: left !important;
    margin-bottom: 10px;
    span {
        align-items: left !important;
        text-align: left !important;
    }
}

.alias-container {
    .additional-row {
        padding-left: 0px;
    }
    .text-input-group {
        padding-left: 0px;
    }
    input {
        width: 80%;
    }
    .card-container {
        padding-left: 0;
    }
}
.completed-dot {
    margin-left: -63px;
    margin-top: -76px;
}
.basic-info {
    .form-group {
        textarea {
            height: 150px;
            font-size: 0.9rem;
        }
        input {
            font-size: 0.9rem;
        }
    }
}
.padding-right-0 {
    padding-right: 0;
}
.font-size-12 {
    font-size: 0.9rem;
}