.checklist-header {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
}

.checklist-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.25s;

    .checklist-item-text {
        padding: 5px;
    }
}

.checklist-item-active {
    border-left: 5px solid darkgrey;
    background-color: lightgrey;
}

.checklist-item:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.sticky-checklist {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
}