.dashed-border {
    border: 1px;
    border-style: dashed;
    border-color: black;
    border-radius: 5px;
}

.dashed-border-warning {
    border: 1px;
    border-style: dashed;
    border-color: #CA0A1A ;
    border-radius: 5px;
}

.dashed-border-interact {
    border: 1px;
    border-style: dashed;
    border-color: #4c9aff ;
    border-radius: 5px;
}

.dropzone-warning-background {
    background-color: #F8DADD;
}

.dropzone-interact-background {
    background-color: #E4F0FF;
}

.dropzone {
    min-height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    .dropzone-text {
        width: 100%;
    }

    .gallery-container {
        width: 100%;
    }

    .row {
        .col {
            padding: 0px;
        }
    }
    

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}




.align-content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

input[type="file"] {
    display: none;
}

.custom-file-upload-button {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #6cb741;
    border-color: #6cb741;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
}

.custom-file-upload-link-active {
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.custom-file-upload-link-inactive {
    background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #B1B7C3;
  text-decoration: none;
  cursor: default;
}

@media only screen and (min-width: 1200px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 8vw);
        grid-gap: 10px;
      }
}

@media only screen and (min-width: 992px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 8vw);
        grid-gap: 10px;
      }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 8vw);
        grid-gap: 10px;
      }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 8vw);
        grid-gap: 10px;
      }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

@media only screen and (min-width: 1200px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

@media only screen and (min-width: 992px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .show-more-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 8vw);
        grid-gap: 10px;
        margin-bottom: 10px;
      }
}

.gallery__img {
width: 100%;
height: 100%;
object-fit: cover;
display: block; 
border-radius: 5px;
transition: .5s ease;
backface-visibility: hidden;
}


.gallery_video__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background: #F5F6F8;
    .spinner-border {
        width: 5rem;
        height: 5rem;
    }
}

video{
    max-width: 100%;
    max-height: 100%;
    display: block; /* remove extra space below image */
}

.video_box{
    width: 210px;    
}  

.gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background: #F5F6F8;
    .spinner-border {
        width: 5rem;
        height: 5rem;
    }
}

.gallery__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background: #F5F6F8;
    .spinner-border {
        width: 3rem;
        height: 3rem;
    }
}

.add-items {
  border: 1px solid lightgrey;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  height: 168px;
}

.border-none {
    border: none;
}

.image-warning-icon {
    display: flex;
    color: orange;
    margin: 5px;
}

.absolute {
    position: absolute;
}

.drag-icon-container {
    display: flex;
    justify-content: flex-end;
}

.image-tools-container {
    width: 100%;
    position: absolute;
    z-index: 120;

    .image-tools {
        position: absolute;
        margin-left: 5px;
        z-index: 5;
        &.left {
            left: 5px;
        }
        &.right {
            right: 5px;
        }
    }
}



.image-flex-items {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image-left-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.drag-icon {
    margin: 5px;
}

.image-drag-active {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.image-drag-inactive-editable {
    width: 100%;
    height: 100%;
    opacity: 1;
    position: relative;

    &:after {
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
        background-color: rgba(0,0,0,0.6);
        z-index: 1;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }

    &:hover:after {
       opacity: 1;
    }

    .form-check {
        padding: 5px;
    }
}

.image-drag-inactive {
    width: 100%;
    height: 100%;
    opacity: 1;
    position: relative;

    .form-check {
        margin-left: 5px;
    }
}

.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-item:hover {
    transition: all .5s;
    -webkit-transition: all 0.5s;
    background:rgba(0,0,0,0.6);
}

.image-tools:hover ~ .edit-item {
    transition: all .5s;
    -webkit-transition: all 0.5s;
    background:rgba(0,0,0,0.6);
}

.drag-item:hover, .image-tools:hover~.drag-item {
    transition: opacity .5s;
    opacity: .5;
    cursor: grab;
}
.filetype-text {
    color: lightgrey;
}

.padding-none {
    padding: 0px;
}

.margin-top-none {
    margin-top: 0px;
}

.margin-top-10 {
    margin-top: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.accordion {
    margin-left: 0px;
    margin-right: 0px;
        
    .card-header {
        margin-top: 20px; 
        border-bottom: 1px solid #fff;
    }

    .no-border {
    border: none;
    }
}

.show-more-less-toggle {
    display: flex;
    justify-content: center;
    align-items: center;

    .show-more-less-icon {
        padding-left: 5px;
    }
}
