.col {
    padding: 0px;
}

#wizard {
    .progress {
        border-radius: 0%;
        margin-top: 55px;
    }
}
.padding-bottom-100 {
    padding-bottom: 100px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-15 {
    margin-right: 15px;
}

#wizard {
    .nav-item {
        display: flex;
        justify-content: center;

        .preview-button {
            position: relative;
            left: -20px;
        }
    }
}