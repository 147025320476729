.wizard-section-card-wrap{
    padding: 0;
    max-width: 100%;

    .wizard-section-card {
        align-items: center;
        flex-direction: row;
        margin-bottom: 1vw;
        margin-right: 1vw;
        color: rgba(0, 0, 0, 0.7);
        border: 1px solid rgba(0, 0, 0, 0.6);
        
        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;            

            .card-title {
                text-transform: uppercase;
                font-size: 1.0rem;
                font-weight: 600;
            }

            .card-subtitle {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 1.2rem;
                font-weight: 700;
            }
            
            .wizard-link {
                font-size: 1rem;
                text-align: right;
                color: rgba(0, 0, 0, 0.6);
                padding: 0;
                font-weight: 500;
            }

            .card-text {
                font-size: 0.8vw;
                font-weight: 500;
            }

            .circle-container {
                padding-left: 0;
            }

            .circle {
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.625);
                height: 3.5vw;
                width: 3.5vw;
                text-align: center;
                padding-top: 16px;
                        
                &.selected {
                    background-color: rgba(165, 165, 165, 0.4);
                }
            }
            .tick {
                color:rgba(0, 0, 0, 0.9);
                margin-top: 6px;
                stroke-width: 0.2;
            }

            .room-card-container{
                margin-top: 20px;
            }
        }
    }
}