#profile-status-card {
    
    .col-no-padding {
        padding: 0px;
    }
    
    .row-center-align {
        align-items: center;
    }
    
    .profile-status-title {
        display: flex;
        margin: 0px;
        align-items: center;
    }
    
    .flex-col {
        display: flex;
    }

    .row {
        margin-bottom: 10px;
    }

    .dropdown > button {
        width: 100%;
    }

    .dropdown > .dropdown-menu {
        width: 100%;
    }

    .progress-percentage-display {
        display: flex;
        justify-content: flex-end;
    }

    .padding-bottom-none {
        padding-bottom: 0px;
    }

    .padding-top-none {
        padding-top: 0px;
    }

    #profile-status-dropdown {
        .dropdown-item {
            word-wrap: break-word;
            font-size: 12px;
        }

        .selected-icon-col {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .selected-item-background {
            background-color: lightgrey;
        }
    }

    .breakdown-link {
        display: flex;
        justify-content: flex-end;
    }

    .breakdown-button {
        padding: 0px;
    }
}

.sticky-profile-status {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
}

