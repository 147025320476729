.wizard-room-card-wrap{
    margin-bottom: 10px;
    .container-fluid {
         padding: 0px
     }

    .card-room-body{
        padding: 0px;
        height: 120px;
    }

    .room-image-container{
        height: 120px;
        display: flex;
        align-content: center;
        padding: 0px;
        .room-image{
            height: 120px;
            width: 120px;
        }
    }

    .room-detail{
        padding-left: 30px;
       
        .room-description{
            height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }

        .room-header-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

        }
    }

}