.margin-top-20 {
    padding-top: 20px;
    padding-bottom: 40px; 
   
    .enquire-link {
        display: flex;
        align-items: flex-start;
        width: 50%;
        margin: 10px 0px 20px;
    }
    
    .card-outer{
        .wizard-card-body{
            padding-right: 5px;
            padding-bottom: 0px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 10px;
        align-content: flex-start;
    }
}
