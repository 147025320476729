.pill{
    border-radius: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active{
    background-color: #b0e9d2;
}

.disabled{
    background-color: #e4e5e7;
}

.rejected{
    background-color: #f2996a;
}

.inprogress{
    background-color: #a7e8f1;
} 

.inreview{
    background-color: #f2996a;
}