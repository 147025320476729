.menu-button {
    .menu-button-container{
        display: flex;
        width: 90%;
        justify-content: center;
    }

    .dropdown .dropdown-menu-button {
        width: 60px;
    }

    .dropdown-col .dropdown {
        display: flex;
        justify-content: center;
    }

    .list-unstyled {
        margin-bottom: 0px;
    }
}