.checkbox-label{
    cursor: pointer;
    margin-bottom: 0;
    align-items: baseline;
    display: flex;
}

.checkbox-margin{
    margin-right: 10px
}

.checkbox-field {
    display: flex;
    flex-direction: row;
}

.accordion-container {
    margin-top: 5px;
    margin-bottom: 5px;
}

.accordion{
    margin-left: -20px; 
    margin-right: -20px; 
   
    .card-body{

        padding: 0;
    }  

    .accordion-border{
        border: 1px solid #fff !important;  /*!important needed to override Bootstrap .card class */
    }
    
    .accordion-collapse-button{
        text-align: center;
    }
    
    .card-header{    
        margin-top: 20px;    
        border-bottom: 1px solid #fff;
    }
    
    .toggleIcon {
        position: relative;
        left: 20px;
    }
    
    .cursor-pointer {
        cursor: pointer;
      }
    
    .card-body-padding {
        padding-top: 0;
    }          
    
    .accordion-column {
        max-width: 50%;
    }
    .section-label {
        margin: 5px 0 10px 0;
    }
    .additional-items-row {
        padding-top: 10px;
    }

}
