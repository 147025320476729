
.home-page{
    display:flex;
    flex-direction: column;
    height: calc(100vh - 54px);
    background-color: white;
    h1 {
        margin-top: 50px;
    }
}
