.wizard-step3-page {
    flex: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 54px);
    
    .wizard-body{
        flex: 1 0 auto;
        min-height: auto;
    }
}

.font-12 {
    font-size: 12px;
}

.center-text {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.warning-text {
    color: red;
}

.warning-border {
    border-color: red;
}