.multiselect {
    .dropdown-menu {
        max-width: 280px;
    }

    .ms-dropdown-item {
        display: flex;
        flex-direction: column;
        padding: 0.25rem 1.5rem;
        cursor: pointer;

        &:hover {
            background-color: #e9ecef;
        }

        &:active {
            background-color: #007bff;
        }

        .ms-dropdown-title {
            display: flex;
            flex-direction: row;

            .form-check {
                margin-right: 4px;
                padding-left: 0px;
                input {
                    cursor: pointer;
                }
            }
        }

        .ms-dropdown-subtitle {
            font-size: 12px;
            white-space: pre-line;
            margin-left: 20px;
        }
    }

    .select-all {
        display: flex;
        flex-direction: row;
        padding: 0.25rem 1.5rem;

        &:hover {
            background-color: #e9ecef;
        }

        &:active {
            background-color: #007bff;
        }

        .form-check {
            padding-left: 0px;
        }
    }

    .dropdown-menu-button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}