.embed-modal {
     width: 600px;
     position: relative;
     display: flex;
     flex-direction: column;    
     pointer-events: auto;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid rgba(0, 0, 0, 0.2);
     border-radius: 0.3rem;
     outline: 0;

    .close-button {
        position: absolute;
        left: 510px;
        top: 4px;
    }

    .close-message-button {
        position: relative;
        left: 7px;
        top: -2px;
    }    

    .video-url{
        width: 510px;
    }

    .video-invalid-url {
        background: #FAE5E7;
        border-color: #CE1024;
    }

    .red-icon {
        color:#CE1024;
    }

    .red-text {
        color:#CE1024;
        font-size: 12px;
    }

    .position-div {
        position: relative;
        top: -15px
    }

    .display-hidden {
        display: none;
    }

    .added-url {
        background: #EBEDF1;
        text-align: left;
        padding: 8px;
        border-radius: 8px;        
        border: 1px solid #7987A1;       
    }

    .added-url-fade-in {
        background: #EBEDF1;
        text-align: left;
        padding: 8px;
        border-radius: 8px;        
        border: 1px solid #7987A1;
        -webkit-animation-duration: 3s;animation-duration: 3s;
        -webkit-animation-fill-mode: both;animation-fill-mode: both;
        -webkit-animation-name: fadIn;
            animation-name: fadeIn;       
    }

    @-webkit-keyframes fadeIn {       
        0% {opacity: 0;}
        100% {opacity: 1;}       
     }

     @keyframes fadeIn {       
        0% {opacity: 0;}
        100% {opacity: 1;}       
     }    

    .grey-icon {        
        color: #7987A1;
    }    

    .display-message-box {        
        padding-bottom: 16px;      
    } 

    .wrap-message {
        word-wrap: break-word;
    }

}

    .flex-display-confirmation {
        display: flex;
    }

    .hide-required-field {
        visibility: hidden ;
    } 