.padding-top-40 {
    padding-top: 40px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-10 {
    padding-top: 10px;
}
.separator-column {
    padding-top: 37px;
    padding-right: 0px;
    padding-left: 9px;
}

.btn-link {
    color: #6cb741;
}

.btn-link:hover {
    color: #6cb741;
}
