.menu-button-container{

    display: flex;
    width: 90%;
    justify-content: center;

    .no-border{
        border: none;
    }

    .no-border:hover{
        background-color: #ffffff;
        color: #000000;
        cursor: default;
    }

}