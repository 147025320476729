.content-bar{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 5px; 

    .progress{
        margin-top: 0;
        height: 5px;
       
        .progress-bar{
            height: 5px;
        }
    }

}