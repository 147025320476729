.sleeping-arrangements {
    .intro-section {
        border: 1px solid #000;
        padding: 20px;
        margin-bottom: 25px;
        .card-title {
            padding-bottom: 15px;
        }
        &:first-child {
            padding-left: 7px;
        }
        .card-title-section {
            justify-content: space-between;
            .section-dot {
                left: -18px;
            }
        }
    }
    .sa-section {
        border: 1px solid #000;
        padding: 20px;
        margin-top: 10px;
        background-color: white;
        .header-row-2-col {
            display: flex;
            justify-content: space-between;
            a {
                padding-top: 0;
            }
        }
    }
    .card-room-body {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.sleeping-arrangements-empty {
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 5rem;
        padding-left: 2.5rem;
    }
    .card-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-text {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.container-div {
    div {
        border: 2px solid grey;

    }
}
