.NavLink-profiles-bold{
    font-weight: 700;
}

.switch{
    width: 110px;
    padding-top: 8px;
    
    .switch-label{
        float:left;
    }

    .switch-toggle{
        float:right;
    }
}

.profile-icon-flex-end {
    display: flex;
    justify-content: flex-end;
}

.profile-sidebar-button-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header .col-md-2 {
    padding-right: 0px;
}

.profile-sidebar-button-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-sidebar-button {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    transition: 0.5s;
}

.profile-sidebar-button:hover {
    background-color: darkgrey;
    cursor: pointer;
}