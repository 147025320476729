.landing-page-section {
    border: 1px dashed black;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder-icon {
    margin: 80px;
}

.appearances-menu-icon-wrapper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    width: 100%;
    .three-dot-container {
        position: relative;
        left: -30px;
    }
}

.appearances-menu-icon:hover {
    cursor: pointer;
}

.col-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.cover-image-block-headers {
    display: flex;
    justify-content: center;
}

.appearances-menu-icon-cover-images {
    margin-right: 10px;
}

.appearances-menu-icon-cover-images:hover {
    cursor: pointer;
}

.appearances-card {
    margin-bottom: 1.5em;
    .card-subtitle {
        margin-top: 0;
    }

    .card-section {
        padding-left: 15px;
   }
}

.appearances-container-margin {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}

