.integrations-card {

    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;

    .form-check {
        padding-left: 0px;
    }

    .affiliate-description {
        padding-left: 23px;
        margin-bottom: 0.5rem;
        font-size: 12px;
    }

    .affiliate-input {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        .affiliate-control {
            width: 50%;
        }
    }


}