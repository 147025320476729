.margin-top-20 {
    padding-top: 20px;
    padding-bottom: 40px; 
    
    .section-card-outer {
        .wizard-section-card-body{
            padding-right: 5px;
            padding-bottom: 0px;           
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .section-card-container {
        display: block;
        padding-top: 10px;
    }
}
.wide-container {
    width: 96%;
}