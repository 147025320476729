$primary: #6cb741;
$primary-highlight: #61A43A;

#root{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

h1 {
    text-align: center;
}

button.btn-primary {
    background-color: $primary;
    border-color: $primary;
}

button.btn-primary:hover {
    background-color: $primary-highlight;
    border-color: $primary-highlight;
}

button.btn-primary:disabled {
    background-color: $primary;
    border-color: $primary;
    opacity: 0.65;
}

button.btn-primary:focus {
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;
}

button.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $primary;
    border-color: $primary;
}

.insights-display {
    display: flex;
    justify-content: center;
}

.btn-link {
    color: $primary;
}

.btn-link:hover {
    color: $primary;
}

.btn-link:focus {
    box-shadow: none;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.margin-right-quarter {
    margin-right: 25%;
}

.margin-left-quarter {
    margin-left: 25%;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-66 {
    margin-top: 66px;
}

.hover-cursor {
    cursor: pointer;
}
.red-text {
    color: red;
}
.red-text-small {
    color: red;
    font-size: 12px;
}
    
.invalid-text-input {
    background: #FAE5E7 !important;     //!important is needed to override Bootstrap .form-control on the local page.
    border-color: #CE1024 !important;   //!important is needed to override Bootstrap .form-control on the local page.
}

.red-icon {
    color:#CE1024;
}

.padding-right-0 {
    padding-right: 0;
}
.upper-case {
    text-transform: uppercase;
}
.padding-top-15 {
    padding-top: 15px;
}
.section-border {
    border: 1px solid #000;
}
.center-content {
    display: block !important;  //!important needed to override .spinner-border class.
    margin-left: auto;
    margin-right: auto;
}