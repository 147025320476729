.profile-list-page {
    background-color: white;
    display: flex;
    padding-top: 100px;
    height: calc(100vh - 54px);

    .profile-list-page-introduction {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .alert-header-warning{
        display:flex;
    }
 
    .warning-icon{
        margin-right: 10px;
        margin-top: 5px;
    }
}
