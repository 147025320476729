.documents-page {
    padding: 0;
    border-color: #343a40;
    border-radius: 0.25rem;

    .tooltip-container {
        position: relative;
        left: 10px;
        top: -2px;
    }

    .section-dot {
        left: 7px !important;
        top: 26px;
        z-index: 10;
    }

    .card-subtitle {
        margin-top: 10px;
        padding-left: 0 !important;    //Turn off the default Bootstrap padding. The !important is needed unless we include the full path for every subtitle.
    }

    .dropzone-container {
        padding: 0;

        .dropzone {
            min-height: 0px;
            .row {
                margin-top: 10px;
            }
        }
    }
    
    .basic-container, .multi-section-card {
        margin: 0px 0 10px 0;
        border: 0;

        .card-body {

            .multi-section-card-title {

                .multiselect {
                    width: 280px;
                    .dropdown-menu {
                        width: 280px;
                    }
                }
            }

            .document-type-container {
                margin-top: 20px;

                .dragsort-container {
                    .draggable-container {
                        padding-top: 8px;
                        padding-bottom: 6px;

                        svg {
                            top: 24px;
                            left: 2px;
                        }
                    }

                    .document-item {
                        border: 0;
                    }

                    .no-handle {
                        cursor: default;
                    }
                }

                .file-upload-link-active {
                    background: none;
                    border: none;
                    padding: 0;
                    font-family: arial, sans-serif;
                    color: #069;
                    cursor: pointer;
                }

                .header-row-2-col {
                    display: flex;
                    justify-content: space-between;

                    a {
                        padding-top: 0;
                    }
                }

                .document-type-label {
                    text-transform: uppercase;
                }
                
                .dropzone-container {
                    padding: 0;

                    .dropzone {
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
                
                .document-item {
                    margin: 0;
                }

                .document-section-header {
                    margin: 0 10px 0 0px;
                    padding: 0 10px 0 0px;

                    .name-of-file {
                        padding-left: 35px;
                    }
                }

                .loading-spinner {
                    margin-top: 10px;
                }
            }
        }
    }
}

/* react-bootstrap OverlayTrigger and Tooltip are added as direct children of <body>.
So you have to access them by ID, and outside of the page structure */
#tooltip-document-visibility  {
    width: 300px;
    max-width: 300px;
    
    .tooltip-inner {
        text-align: left;
        width: 300px;
        max-width: 300px;
        padding: 10px 15px 10px 15px;
    }
}