@import"../../../../../node_modules/bootstrap";
@import"../../../../../node_modules/bootstrap/scss/mixins";

.image-edit-modal {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .footer-buttons {
        display: flex;
        margin: auto;
        width: 90px;
        padding: 10px 0;
        :first-child svg {
            margin-top: -5px;
        }
        :nth-child(2) { 
            margin-left: 10px;
            svg {
                margin-top: -2px;
            }
        }
    }
    
    .right-side-bar {
        padding-right: 0;
        min-width: 306px;
    }

    .container-fluid-height{
        height:100%
    }

    .edit-container {
        height: 90%;
        background-color: grey;
        position: relative;
        padding: 0;
        margin: 0;
    }

    .container-background {        
        padding: 0;
        margin: 0;
    }

    .spinner-border {
        position: absolute;
        left: 50%;
    }

    .button-column {
        width: 30px;
        #next-button {
            right: 15px;
        }
        #back-button {
            left: 10px;
        }
    }

    .header-container {
        padding-right: 0;        
        max-width: 100%;
        margin: 0;
        justify-content: space-between;
        display: flex;
        .row {
            width: 100%;
        }
    }

    .header-inner-container {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }

    .middle-button-container {
        display: flex;
        align-items: center;
    }

    .lower-text {
        font-size: 0.9rem;
    }
    
    .close {
        margin-left: 0;
    }

    .save {
        margin-left: 15px;
    }

    .back-next-button {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 36px;
        width: 36px;
    }

    .image-details {
        padding: 0;
    }

    .credit-section {
        margin-top: 10px;
    }

    .edit-image-container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 10px;
        justify-content: center;
        img {
            border: 1px solid #555555;
            display:  flex;
            align-self: center;
            max-height: 65vh;
            max-width: 100%;
        }
    }
    
    .modal-dialog {
        width: 100% ;
        height: 100% ;
        margin: 0;
        padding: 0;
    }

    .modal-body {
        flex-wrap: nowrap;
        padding:  5px 10px 0px 10px
    }

    .modal-content {
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
        height: 100%;
        min-height: 100%;
        border-radius: 0;
        width: 100%;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 100%;
            margin: 0;
        }
    }

    .modal-content {
        overflow: auto;
    }
}

@include media-breakpoint-down(sm) {
    .image-edit-modal { 
        .modal-body {
        flex-wrap: wrap;
        }
    }
  }