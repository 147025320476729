$primary: #6cb741;

.card-page12-container {
    text-align: center;
    height: 600px;
    padding-top: 20%;

    .spinner{
        color: $primary;
    }
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

span {
    margin: 0 10px;
}

span:first-of-type {
    margin-left: 0;
}

span:last-of-type {
    margin-right: 0;
}